import { useShepherd } from "vue-shepherd/dist/vue-shepherd.ssr.js";

const showcase = useExamShowcase();

export function useOnboardingTour() {
  const tour = useShepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      exitOnEsc: true,
      scrollTo: { behavior: "smooth", block: "center" },
      classes: "text-justify px-3 rounded-2xl",
      cancelIcon: {
        enabled: true,
      },
    },
  });

  const addSteps = () => {
    tour.addStep({
      title: "Schön, dass du hier bist!",
      text: "Dürfen wir dir in 1 Minute die <strong>wichtigsten Funktionen zeigen</strong>?",
      attachTo: {
        element: "#tour-btn",
        on: "top",
      },
      classes: "!top-[calc(100vh-225px)]",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Überspringen",
          action: tour.cancel,
        },
        {
          text: "Tour starten",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Suche",
      text: `Beginne mit der Suche nach Gesetzesnormen oder nach Stichworten in Schemata / Notizen.`,
      attachTo: {
        element: "#step-1",
        on: "bottom",
      },
      classes: "mt-6",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 1/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Spalte 1: Übersichtsboxen",
      text: `Hier findest du die <strong>zum Gesetz</strong> in Spalte 2 gehörigen <strong>Übersichtsboxen</strong> und navigierst zu anderen Normen oder zum ausführlichen Inhaltsverzeichnis.`,
      attachTo: {
        element: "#step-2",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 2/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Spalte 2: Gesetz",
      text: "Hier findest du stets deine <strong>Gesetzesnorm</strong> und personalisierst sie.",
      attachTo: {
        element: "#step-3",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 3/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Toolbox",
      text: "<strong>Personalisiere</strong> dein Gesetzbuch durch: Schemata, Notizen, Unterstreichungen, Markierungen, Verweise, Bookmarks.",
      attachTo: {
        element: "#step-4",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 4/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Tabs",
      text: "Öffne <strong>mehrere Normen</strong> in Tabs nebeneinander.",
      attachTo: {
        element: "#step-5",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 5/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Spalte 3: Inhalte",
      text: `In Spalte 3 findest du stets die durch LexMea oder durch dich an die Norm gehefteten <strong>Inhalte</strong> (Schemata und Notizen). <div class="text-xs italic pt-3">Tipp: Nutze beim Erstellen von Schemata die <strong>Formatvorlagen</strong> für Überschriften und Boxen wie Definitionen oder Aufbauhinweise.</div>`,
      attachTo: {
        element: "#step-6",
        on: "left",
      },
      classes: "ml-[-25px]",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 6/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Spalte 4: Übersichtsboxen",
      text: `Hier findest du die zu den <strong>Inhalten</strong> in Spalte 3 gehörigen <strong>Übersichtsboxen</strong> und navigierst zu anderen Schemata oder Notizen.`,
      attachTo: {
        element: "#step-7",
        on: "left",
      },
      classes: "ml-[-25px]",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Weiter 7/8",
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: "Bibliothek",
      text: `Navigiere vom Arbeitsbereich zur Bibliothek für eine umfassende <strong>Gesamtübersicht</strong> über deine Personalisierungen und Gesetze.`,
      attachTo: {
        element: "#step-8",
        on: "bottom",
      },
      classes: "mt-6",
      cancel: tour.cancel,
      buttons: [
        {
          text: "Zurück",
          action: tour.back,
        },
        {
          text: "Loslegen",
          action: tour.complete,
        },
      ],
    });
  };

  const localTourCompleted = useLocalStorage("lexmea-tour-completed", false, {
    mergeDefaults: true,
    initOnMounted: true,
  });

  const pageContext = usePageContext();
  const isMounted = useMounted();
  const isLargeScreen = useIsLargeScreen();
  const currentPath = toRef(pageContext, "urlPathname");

  const canShowTour = computed(
    () =>
      isLargeScreen.value &&
      currentPath.value.startsWith("/gesetz") &&
      !showcase()
  );

  const startTour = () => tour.start();
  const finishTour = () => (localTourCompleted.value = true);

  const setupOnboardingTour = () => {
    addSteps();
    ["complete", "cancel"].forEach((event) => tour.on(event, finishTour));

    if (!localTourCompleted.value) {
      startTour();
    }
  };

  const { cookieModalDisabled } = useCookieModal();

  watchEffect(() => {
    if (!isMounted.value || !cookieModalDisabled.value) {
      return;
    }
    if (!canShowTour.value) {
      return tour.cancel();
    }
    return setupOnboardingTour();
  });

  return { canShowTour, startTour };
}
