<template>
  <header class="z-[22] w-full bg-lexmea-blue-400 p-2.5 py-2 md:py-2">
    <div class="flex items-center justify-start md:gap-4">
      <!-- Hamburger menu Icon -->
      <div
        v-if="!showcase"
        class="flex w-40 items-center transition-all duration-100 xl:w-48"
        :class="
          isFocused ? 'max-md:w-0 max-md:gap-0 max-md:opacity-0' : 'max-md:mr-2'
        "
      >
        <button
          ref="hamburgerButton"
          v-tooltip="'Menü'"
          class="h-6 w-6 landscape:ml-2"
          title="Menü"
        >
          <img alt="menu" class="h-6 w-6" :src="hamburgerIcon" />
        </button>
        <LibraryNavigator class="ml-3" title="Buecherregal">
          <span class="sr-only">Zum Bücherregal</span>
          <LexmeaLogo class="aspect-auto h-6 w-[5.5rem]" />
        </LibraryNavigator>
      </div>
      <div
        ref="searchbarElement"
        class="order-3 mr-2 w-full flex-grow justify-self-start transition-all duration-100 md:order-2 md:max-w-xl"
      >
        <SearchBar
          id="step-1"
          class="transition-all duration-300"
          :legalText="legalText"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
        />
      </div>
      <div
        class="order-2 flex gap-3 transition-all duration-100 md:order-3"
        :class="
          isFocused ? 'max-md:w-0 max-md:gap-0 max-md:opacity-0' : 'max-md:mr-2'
        "
      >
        <LibraryNavigator
          id="step-8"
          v-tooltip="$t('library')"
          class="group relative h-6 w-6 cursor-pointer"
          :title="$t('library')"
        >
          <img
            :src="libraryIcon"
            class="h-6 w-6 object-contain"
            :alt="$t('library')"
          />
        </LibraryNavigator>
        <ActiveParagraphLink
          v-tooltip="$t('workspace')"
          class="group relative h-6 w-6 cursor-pointer"
          :class="{ 'animate-bounce': animateBounceWorkspaceButton }"
          title="$t('workspace')"
        >
          <span class="sr-only">{{ $t("workspace") }}</span>
          <WorkspaceIcon class="h-6 w-6" :filled="isOnWorkspace" />
        </ActiveParagraphLink>
      </div>
      <Transition name="fade">
        <div v-if="user && !online" :title="$t('offline')" role="alert">
          <i-ri-wifi-off-line
            class="h-6 w-6 stroke-current text-gray-100"
            aria-hidden="true"
          />
          <span class="sr-only">{{ $t("offline") }}</span>
        </div>
      </Transition>
      <div
        v-if="!showcase"
        class="relative order-4 ml-auto flex justify-center"
      >
        <div v-if="devMode" class="text-white">
          <button
            class="mr-2 inline-block"
            @click="$i18n.locale = $i18n.locale === 'de' ? 'en' : 'de'"
          >
            {{ $i18n.locale === "de" ? "DE" : "EN" }}
          </button>
        </div>
        <button v-if="user" ref="userDropdownButton" title="Einstellungen">
          <UserProfileIcon
            class="h-8 w-8"
            :profileImage="user.profile_image"
            :username="user.name"
          />
        </button>
        <button
          v-else
          v-tooltip="'Profil'"
          class="inline-flex h-6 w-6 items-center rounded-full"
          @click="openModal('login')"
        >
          <img
            loading="lazy"
            class="h-6 w-6"
            :src="loggedOut"
            alt="logged out"
          />
        </button>
        <teleport to="#teleported">
          <Transition name="scale">
            <UserDropdown
              v-if="userDropdownOpen && user"
              ref="userDropdown"
              :user="user"
              class="absolute right-4 top-14 z-30 min-w-max origin-top-right"
              @close="toggleUserDropdown(false)"
              @openFeedback="openFeedback"
            />
          </Transition>
        </teleport>
      </div>
    </div>

    <template v-if="!showcase">
      <!-- Full-screen Menu-->
      <LexmeaFullScreenMenu
        v-if="isSmallerThanLg"
        ref="hamburgerMenu"
        :open="isHamburgerMenuOpen"
        @close="toggleHamburgerMenu(false)"
      />

      <LexmeaHamburgerMenu
        v-else
        ref="hamburgerMenu"
        class="mt-1.5"
        :open="isHamburgerMenuOpen"
        @close="toggleHamburgerMenu(false)"
      />
    </template>

    <!-- Full-screen Search -->
    <LexmeaMobileFullScreenSearch
      :open="isSearchModalActive"
      @close="isSearchModalActive = false"
    />
  </header>
</template>

<script lang="ts" setup>
import loggedOut from "~/assets/header/loggedout.svg";
import libraryActive from "~/assets/header/library-active.svg";
import library from "~/assets/header/library.svg";
import hamburgerIcon from "~/assets/header/hamburger-menu.svg";
import useLexMeaTailwindBreakpoints from "~/composables/useLexMeaTailwindBreakpoints";

const UserDropdown = defineAsyncComponent(
  () => import("~/components/header/UserDropdown.vue")
);
const LexmeaFullScreenMenu = defineAsyncComponent(
  () => import("~/components/header/LexmeaFullScreenMenu.vue")
);
const LexmeaHamburgerMenu = defineAsyncComponent(
  () => import("~/components/header/LexmeaHamburgerMenu.vue")
);
const LexmeaMobileFullScreenSearch = defineAsyncComponent(
  () => import("~/components/header/LexmeaMobileFullScreenSearch.vue")
);

const showcase = useExamShowcase();

const { isSmallerThanLg } = useLexMeaTailwindBreakpoints();
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { openModal } = useLoginModal();

const { data: legalText } = useCurrentLegalText();

const { searchbarElement } = useSplitpane();

const navbarWidthStore = useNavbarWidthStore();
const { x } = useElementBounding(searchbarElement);
watch(x, (x) => navbarWidthStore.setWidth(x));

const {
  isMenuOpen: userDropdownOpen,
  menuElement: userDropdown,
  menuTriggerElement: userDropdownButton,
  toggleMenu: toggleUserDropdown,
} = useMenuToggle();

const {
  isMenuOpen: isHamburgerMenuOpen,
  menuElement: hamburgerMenu,
  menuTriggerElement: hamburgerButton,
  toggleMenu: toggleHamburgerMenu,
} = useMenuToggle();

const isSearchModalActive = ref<boolean>(false);

const pageContext = usePageContext();
const currentPath = toRef(pageContext, "urlPathname");

const libraryIcon = computed(() =>
  currentPath.value.startsWith("/bibliothek") ? libraryActive : library
);

const isOnWorkspace = computed(() => currentPath.value.startsWith("/gesetz/"));

// this piece of code is triggered by the CTA button on the product page (component: TrialButton.vue)
const workspaceIconAnimationState = useLocalStorage(
  "workspace-icon-animation",
  "",
  {
    initOnMounted: true,
  }
);
const animateBounceWorkspaceButton = ref(false);

function triggerWorkspaceButtonAnimation() {
  if (
    currentPath.value.startsWith("/gesetz/") &&
    workspaceIconAnimationState.value
  ) {
    setTimeout(() => {
      workspaceIconAnimationState.value = null;
      animateBounceWorkspaceButton.value = true;
      setTimeout(() => {
        animateBounceWorkspaceButton.value = false;
      }, 3500);
    }, 2500);
  }
}

watch(currentPath, () => {
  toggleHamburgerMenu(false);
  triggerWorkspaceButtonAnimation();
});

const online = useOnline();
const { success, warning } = useToast();

watch(online, (online) => {
  if (online) {
    success("Du bist wieder online.");
  } else {
    warning("Du bist offline, Änderungen werden nicht gespeichert.");
  }
});

const isFocused = ref(false);

const devMode = import.meta.env.DEV;

const { open: openFeedback } = useFeedbackModal();
</script>
