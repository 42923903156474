import { useCookies } from "@vueuse/integrations/useCookies";

const COOKIE_MODAL_DISABLED = "cookieModalDisabled";

export function useCookieModal() {
  const cookies = useCookies([COOKIE_MODAL_DISABLED]);
  const isShowcase = useExamShowcase();

  const cookieModalDisabled = computed({
    get: () => isShowcase || cookies.get(COOKIE_MODAL_DISABLED),
    set: (val: boolean) => cookies.set(COOKIE_MODAL_DISABLED, val),
  });

  return { cookieModalDisabled };
}
